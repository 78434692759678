import '@unocss/reset/tailwind.css';
import 'uno.css';

import { createApp } from 'vue';
import { createPinia } from 'pinia';
import FloatingVue from 'floating-vue';
import 'floating-vue/dist/style.css';
import { createHead } from '@vueuse/head';
import './initializers/canny';

// @ts-expect-error - should try and find an alternative to this package, manually implement something or a new package
import Vue3Sanitize from 'vue-3-sanitize';

import * as Sentry from '@sentry/vue';
import { BrowserTracing } from '@sentry/tracing';

import 'ag-grid-community/styles/ag-grid.min.css';
import 'ag-grid-community/styles/ag-theme-alpine.css';
import { LicenseManager } from 'ag-grid-enterprise';
import App from './App.vue';
import router from '@/router/index.js';
import './assets/fonts.css';
import './assets/index.css';

const agGridLicenseKey = import.meta.env.VITE_AGGRID_KEY;
LicenseManager.setLicenseKey(agGridLicenseKey);

const amplifyRegex = /^https:\/\/(pr-\d+|main|develop)\.d2ef1ow0ipfouv\.amplifyapp.com$/;

const head = createHead();

const defaultSanitizationOptions = {
  allowedTags: ['span', 'br'],
};

const app = createApp(App)
  .use(createPinia())
  .use(router)
  .use(FloatingVue)
  .use(head)
  .use(Vue3Sanitize, defaultSanitizationOptions);

// using node env to enable/disable tracing, this is seperate from VITE_APP_ENV which is used to identify app in traces
if (import.meta.env.PROD) {
  Sentry.init({
    app,
    dsn: 'https://f229bd5193d94da398f9e4d55d82b60a@o331479.ingest.sentry.io/6178850',
    integrations: [
      new BrowserTracing({
        routingInstrumentation: Sentry.vueRouterInstrumentation(router),
        tracingOrigins: ['localhost', 'appstaging.contactmonkey.com', 'app.contactmonkey.com', amplifyRegex],
      }),
    ],
    // @ts-expect-error - this is a valid option its weird and Vite like
    release: __APP_VERSION__,
    environment: import.meta.env.VITE_APP_ENV,
    maxBreadcrumbs: 30,
    // Set tracesSampleRate to 1.0 to capture 100%
    // of transactions for performance monitoring.
    // We recommend adjusting this value in production
    tracesSampleRate: 0.1,
  });
}

app.mount('#app');
