import { defineStore } from 'pinia';
import * as Sentry from '@sentry/vue';
import { z } from 'zod';
import { cmApi } from '@/api/instance';
import type { OrganizationTeam, User, UserApi } from '@/types/user';
import { currentUserSchema } from '@/types/user';

// TODO: with BE team check if error format is proper
const axiosErrorSchema = z.object({
  response: z.object({
    data: z.object({
      error: z.object({
        message: z.string(),
      }),
    }),
    status: z.number(),
  }),
});

// type axiosError = z.infer<typeof axiosErrorSchema>;

export const useUserStore = defineStore('users', {
  state: () => ({
    currentUser: null as User | null,
    currentTeam: null as OrganizationTeam | null,
    availableTeams: [] as OrganizationTeam[],
  }),

  getters: {
    ownerOrAdmin: state => !!(state.currentUser?.role === 'owner' || state.currentUser?.role === 'administrator'),
    smsUser: state => (!state.currentUser?.mail_merge_campaigns_enabled && state.currentUser?.sms_campaigns_enabled),
    mailUser: state => (!state.currentUser?.sms_campaigns_enabled && state.currentUser?.mail_merge_campaigns_enabled),
    dualUser: state => (state.currentUser?.sms_campaigns_enabled && state.currentUser.mail_merge_campaigns_enabled),
    smsTrialUser: state => ((!state.currentUser?.sms_campaigns_enabled && state.currentUser?.mail_merge_campaigns_enabled) && state.currentUser?.sms_campaign_trial_enabled),
    socialChannelsUser: state => (state.currentUser?.organization?.social_channels_enabled),
    multiLanguageUser: state => (state.currentUser?.organization?.email_translations_toggle_visible && state.currentUser?.organization.email_translations_enabled),
    msTeamsUser: state => (state.currentUser?.organization?.social_channels_enabled && state.currentUser.organization?.ms_teams_enabled),
    isUserSmsOnly: state => (!state.currentUser?.mail_merge_campaigns_enabled && state.currentUser?.sms_campaigns_enabled && !(state.currentUser?.sms_campaigns_enabled && state.currentUser.mail_merge_campaigns_enabled)),
    isUserMailOnly: state => (!state.currentUser?.sms_campaigns_enabled && state.currentUser?.mail_merge_campaigns_enabled && !(state.currentUser.sms_campaigns_enabled && state.currentUser.mail_merge_campaigns_enabled)),
    isUserDual: state => (state.currentUser?.sms_campaigns_enabled && state.currentUser.mail_merge_campaigns_enabled),
  },

  actions: {
    async fetchUser() {
      try {
        const { data } = await cmApi<UserApi>('/users/info');
        const parsedData = currentUserSchema.safeParse(data.user);
        if (!parsedData.success) {
          console.error(parsedData.error);
          console.error('invalid user data');
          return
        }
        this.currentUser = parsedData.data;
        this.availableTeams = parsedData.data.organization_teams;
        Sentry.setUser({ id: parsedData.data.id });

        // TODO: should consider moving appcues and canny calls into onMounted hook on app.vue and abstract the JS out
        if (this.currentUser.internal_communication) {
          if (import.meta.env.VITE_APPCUES_ENABLED == 'enabled' && this.currentUser) {
            window.AppcuesReady(() => {
              // please do not rename or remove the following variables as they are used by Appcues
              // if you feel like you have to, please contact Zain Wania
              // last_login: this.currentUser?.last_sign_in_at,  <-- this one is being removed temporarily since it is not being return by user info
              window.Appcues.identify(`${this.currentUser?.region}_${this.currentUser?.id}`, {
                name: `${this.currentUser?.firstname}`,
                account_id: `${this.currentUser?.region}_${this.currentUser?.id}`,
                region: this.currentUser?.region,
                joined_ic_org_at: this.currentUser?.joined_ic_org_at,
                ic_or_sales: 'ic',
                plan_type: this.currentUser?.organization.plan_type,
                org_expires_on: this.currentUser?.organization.expires_on,
                addin_type: this.currentUser?.organization.org_type,
                org_id: `${this.currentUser?.region}_${this.currentUser?.organization.id}`,
                role: this.currentUser?.role,
                campaign_sending: this.currentUser?.send_mail_merge_campaigns_enabled,
                click_maps: this.currentUser?.click_maps_enabled,
                show_campaign_template: this.currentUser?.show_campaign_template,
                read_time: this.currentUser?.readtrack_enabled,
                dashboard_sending: this.currentUser?.gmail_dashboard_sending_enabled,
                surveys_and_enps: this.currentUser?.social_reaction_enabled,
                comments_for_surveys_and_enps: this.currentUser?.sr_comment_enabled,
                email_customer: this.currentUser?.mail_merge_campaigns_enabled,
                ms_teams_enabled: this.currentUser?.organization.ms_teams_enabled,
                social_channels_enabled: this.currentUser?.organization.social_channels_enabled,
                multi_language_templates_enabled: this.currentUser?.organization.email_translations_toggle_visible,
                org_name: this.currentUser?.organization.name,
                sms_plg_enabled: this.currentUser?.sms_campaign_trial_enabled,
                sms_campaigns_enabled: this.currentUser?.sms_campaigns_enabled,
                events_management_enabled: this.currentUser?.events_management_enabled,
                list_management_enabled: this.currentUser?.list_management_enabled,
                custom_landing_page_enabled: this.currentUser?.organization.custom_landing_page_enabled,
                custom_fonts_enabled: this.currentUser?.organization.custom_fonts_enabled,
                okta_enabled: this.currentUser?.organization.okta_enabled,
                remaining_licenses: this.currentUser?.organization.remaining_licenses,
                sms_plg_sent: this.currentUser?.sms_trial_campaigns_sent,
                has_created_template: this.currentUser?.has_created_template,
                can_sign_in_to_outlook: this.currentUser?.can_sign_in_to_outlook_mail,
                signed_in_to_outlook: this.currentUser?.signed_in_to_outlook,
                can_sign_in_to_gsuite: this.currentUser?.can_sign_in_to_gsuite,
                signed_in_to_gsuite: this.currentUser?.gsuite_connected,
                outlook_dashboard_sending_enabled: this.currentUser?.outlook_dashboard_sending_enabled,
                outlook_dashboard_sent_successfully: this.currentUser?.outlook_dashboard_sent_successfully,
                bee_ai_meta_tag_enabled: this.currentUser?.bee_ai_meta_tag_enabled,
              });
            });
          }

          if (typeof window.Canny === 'function') {
            // check with Nick if adding the window.Canny call is ok to do
            window.Canny('identify', {
              appID: '65947205893793303f300b7c',
              user: {
                email: this.currentUser.email,
                name: `${this.currentUser.firstname} ${this.currentUser.lastname}`,
                id: `${this.currentUser.region}_${this.currentUser.id}`,
              },
            });
          }
        }
      }
      catch (error) {
        const parsedError = axiosErrorSchema.safeParse(error);
        if (parsedError.success) {
          console.error(parsedError.data.response.data.error);
        }
        // keeping the original error for now to ensure we continue to get the error message
        // TODO: explicitly check if backend sends errors consistently then remove the else block
        else {
          console.error(error);
          // @ts-expect-error - this is a workaround for a bug in zod where it doesn't recognize the error as a zod error, see todo above
          console.error(error.response);
        }
      }
    },

    updateCurrentTeam(id: number) {
      const newTeam = this.availableTeams.find(team => team.id === id);
      if (newTeam) {
        this.currentTeam = newTeam;
      }
    },
  },
});
